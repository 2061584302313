#zoom-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  visibility: visible; /* Always visible, even when on mobile */
  z-index: 20;
  position: absolute;
  left: 35%;
  width: 30%;
  top: 0;
}

#zoom-controls div {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.btn-inactive {
  background-color: transparent;
  color: #ccc;
}

.btn-inactive:hover {
  background-color: transparent;
  color: #ccc;
}

@media (max-width: 500px) {
  #zoom-controls {
    background-color: #eee;
    border-radius: 7px;
    height: 38px;
    margin-top: 7px;
    padding: 0 7px 3px 7px;
  }
}

.zoom-btn {
  border: none;
  width: 20px;
}

#zoom-display {
  margin: 3px 10px 0 10px;
}
