#root {
  width: 100vw;
}

/* @font-face {
  font-family: 'Cogito';
  src: url('./fonts/Cogito-Regular.otf');
  font-weight: normal;
  font-style: normal;
} */

body {
  height: 100%;
  width: 100%;
  background-color: #f6f6f8;
}
.btn {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
}
.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active {
  background-image: none;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-error-reload {
  float: left;
}
