.annotationLayer { 
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.2;
    line-height: 1.0;
}

.annotationLayer > section {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
}

.annotationLayer > .linkAnnotation > a {
    position: absolute;
    font-size: 1em;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}