.pdf-context {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#container-pdf {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  height: calc(100% - 45px);
  width: 100%;
  overflow: auto;
  left: 0px;
  right: 0;
  bottom: 0;
  top: 45px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#container-pdf.no-zoom {
  top: 15px;
}

.pdf-viewer-merged {
  margin: 0 auto;
  width: 100%;
}
.pdf-viewer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pdf-page {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#container-pdf.sidebarOpen {
  left: 110px;
  width: calc(100% - 110px);
}

#sidebarContainer {
  z-index: 100;
  position: absolute;
  left: 110px;
  width: 0;
  bottom: 0;
  margin-left: -110px;
  top: 45px;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebarContainer.sidebaropen {
  width: 110px;
}
#sidebarContainer .pdf-page {
  cursor: pointer;
  margin: 0 10px 10px;
}

.document-id {
  position: absolute;
  bottom: 0;
  padding: 10px;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  color: #949494;
  font-size: 0.8em;
}

#sidebar-icon,
#sidebar-icon-hovered {
  width: 22px;
  height: 22px;
  margin: 11px 17px;
  cursor: pointer;
}

#sidebarWrapper img#sidebar-icon-hovered {
  display: none;
}

#sidebarWrapper:hover img#sidebar-icon-hovered {
  display: inline;
}

#sidebarWrapper:hover img#sidebar-icon {
  display: none;
}

#download-action {
  margin: 7px 10px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#download-action:hover {
  cursor: pointer;
}
#download-action span {
  align-self: center;
  margin-left: 6px;
}
#download-icon,
#download-icon-hovered {
  vertical-align: middle;
  width: 20px;
  height: 18px;
  align-self: center;
}

#print-action {
  margin: 7px 0px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#print-action span {
  align-self: center;
  margin-left: 6px;
}
#print-action:hover {
  cursor: pointer;
}
#print-icon,
#print-icon-hovered {
  vertical-align: middle;
  align-self: center;
}

.sidebaropen {
  animation: fade-in-keyframes 1s;
}
@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.emailContainer div {
  color: pink;
}

#menu {
  display: flex;
  flex-direction: row;
  height: 38px;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 56px;
  width: calc(100% - 56px);
  z-index: 19;
}

.menu-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0 10px;
}

/* Download */
#download-action:hover img#download-icon {
  display: none;
}
#download-action img#download-icon-hovered {
  display: none;
}
#download-action:hover img#download-icon-hovered {
  display: inline-block;
}
/* Print */
#print-action:hover img#print-icon {
  display: none;
}

#print-action img#print-icon-hovered {
  display: none;
}

#print-action:hover img#print-icon-hovered {
  display: inline-block;
}
.error {
  margin: 50px auto;
  max-width: 480px;
}
.btn-error-reload {
  margin: 40px 0;
}

#loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}

#loader-icon {
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  align-self: center;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Mobile case: We hide the sidebase and the menu  */
@media (max-width: 500px) {
  #container-pdf {
    height: 100%;
    top: 0px;
  }

  #container-pdf.sidebarOpen {
    left: 0px;
    width: 100%;
  }

  #sidebarContainer {
    display: none;
  }

  #sidebarWrapper {
    display: none;
  }

  #menu {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    background-color: #f6f6f8;
  }

  #print-action {
    display: none;
  }
  #download-action {
    display: block;
    background-color: #f6f6f8;
  }
}
